import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';

import { HttpClient, HttpHeaderResponse, HttpHeaders } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';


import ApiResponseModel from './models/ApiResponseModel';

import HttpRequestType from './enums/EnumHttpRequestType';
import { Http, Response, RequestOptionsArgs, ResponseContentType, Headers } from '@angular/http';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private _http: HttpClient,
    private _httpUpload:Http) {

  }



  TimeoutRequest: number = 5000;
  TimeoutDownloadRequest: number = 10000;
  TimeoutLogin: number= 10000;

  /*
   * sendRequest
   */
  private handleResponse(response: any) {
    var jsonData: ApiResponseModel<any>;

    try {

      jsonData = response as ApiResponseModel<any>;

      if (jsonData === null){

        console.error({ _error: "No response from the server!" }, jsonData)
      }else{

        if (jsonData.Code !== 200)
          console.error({ _error: jsonData.Message }, jsonData)
      }

    } catch (e) {
      console.error({ _error: e.Message }, e)

    }


    return jsonData;
  }

  private handleError(error: any) {
    console.error('ApiService::handleError', error);

    return throwError(error);
  }




  /*
   * sendRequest
   */
  public sendRequest<T>(reqType: HttpRequestType, endpoint: string, query: Object = null, body: any = null): Observable<any> {
    //Recupero il token dal local storage
    const token = localStorage.getItem("TOKEN");

    var options = { headers: { 'Authorization': token } }

    endpoint = environment.seviceUrl.concat(endpoint);

    //formatto l'endpoint
    if (query !== null)
      endpoint = endpoint.concat(this._serializeParameters(query));

    var ob: Observable<any>;
    switch (reqType) {
      case HttpRequestType.GET:
        ob = this._http.get(endpoint, options);
        break;
      case HttpRequestType.POST:
        ob = this._http.post(endpoint, body, options);
        break;
      case HttpRequestType.DELETE:
        ob = this._http.delete(endpoint, options);
        break;
      case HttpRequestType.PUT:
        ob = this._http.put(endpoint, body, options);
        break;
      case HttpRequestType.HEAD:
        ob = this._http.head(endpoint, options);
        break;
      case HttpRequestType.PATCH:
        ob = this._http.patch(endpoint, body, options);
        break;
      case HttpRequestType.OPTIONS:
        ob = this._http.options(endpoint, options);
        break;
      case HttpRequestType.MULTIPARTPOST:
        ob = this.multipartPost(endpoint, query, body);
        return ob;
    }

    return ob.pipe(
      map(this.handleResponse),
      catchError(this.handleError)
    );
  }



  public multipartPost(endpoint:string, query:Object = null, body:any = null): Observable<any> {
    const token = localStorage.getItem('xxTOKENxx');

    const options: any = {
      responseType : ResponseContentType.Json,
      headers: new HttpHeaders()
    }

    if(body){
      options.body = body;
    }

    options.headers = new Headers();
    options.headers.set('Accept', 'application/json' );

    if( !HttpRequestType.MULTIPARTPOST ){
      options.headers.set('Content-Type', 'application/json' );
      delete options.headers['Content-Type'];
    }

    if( token ){
      options.headers.set('Authorization', token );
    }

    var ob: Observable<any>;

    ob = this._httpUpload.post(endpoint, body, options);

    return ob.pipe(
      map(this.handleResponseMultipart),
      catchError(this.handleError)
    );
  }

  /*
   * sendRequest
   */
  private handleResponseMultipart ( response: Response ){
    let jsonData : ApiResponseModel<any>;
    try{
      jsonData = response.json();
    }catch( e ){
      return { _error: e.Message }
    }
    if( jsonData === null )
      return { _error: 'No response from the server!' }
    else if( jsonData.Code !== 200 )
      return { _error: jsonData.Message }

    return jsonData;
  }



  /*
  *   {simo: "simo", bho : "ciao"}  ====> ?simo=simo&bho=ciao
  */
  private _serializeParameters = (object): string => {
    var str = "";
    for (var tmp in object) {
      var x = object[tmp];

      str += "&" + tmp + (x == null ? "" : "=" + x);
    }
    //Sostituisco il primo & con ?
    if (str === "")
      return str;
    else
      return "?" + str.substring(1, str.length);
  };





  public downloadFile$( endpoint:string, fileName : string, query : any, disableAutomaitcDownload: boolean = false ) : Observable<Blob> {

    endpoint = environment.seviceUrl.concat(endpoint);
    endpoint = endpoint.concat( this._serializeParameters( query ) )

    return this._http.get<File>(endpoint, {responseType: 'blob' as 'json'})
      .pipe(
          map( res =>  new Blob([res], {type: res.type}) ),
          tap( file => {

              if( disableAutomaitcDownload ) return;

              const nav : any=window?.navigator
              //  IE
              if (nav?.msSaveOrOpenBlob) {
                nav.msSaveOrOpenBlob(file);
                return;
              }
              const blob = window.URL.createObjectURL(file);
              const link = document.createElement('a');
              link.href = blob;
              link.download = fileName;

              //  Version link.click() to work at firefox
              link.dispatchEvent(new MouseEvent('click', {
                bubbles: true,
                cancelable: true,
                view: window
              }));

              setTimeout(() => { //  firefox
                window.URL.revokeObjectURL(blob);
                link.remove();
              }, 100);

            }
          )
     );

  }




  /*
  *
  */
  public getToken(endpoint: string, username: string, password: string, grant_type: string, device_type: number = null) {

    endpoint = environment.seviceUrl.concat(endpoint);

    return this._http.post(endpoint, this._serializeParameters({ username, password, grant_type, device_type }).substring(1)).pipe(
      map(token => {
        var jsonData: any;
        try {
          jsonData = token;
        } catch (e) {
          return { _error: e.Message }
        }
        return jsonData;
      }),
      catchError((errorResponse: Response | any) => {
        var err;
        console.error('ApiService::handleError', errorResponse);
        try {
          err = errorResponse.error.error_description
        } catch (e) {
          err = e;
        }

        return throwError(err);
      })
    );

  }






}

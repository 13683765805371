import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DirittoModel } from 'src/app/shared/class/models/DirittoModel';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  
  _Token        : string;
  _TokenExpires : any;



  /*
  *
  */
  constructor( private _Router : Router ) { 
    this._initData();
  }



  /*
  *
  */
  private _initData(){
    this._Token        = localStorage.getItem("xxTOKENxx");
    this._TokenExpires = new Date( localStorage.getItem("xxTOKEN_EXPIRESxx") );
  }
  




  /*
  *
  */
  public isLoggedIn() : any{
    this._Token        = this._Token        || localStorage.getItem("xxTOKENxx");
    this._TokenExpires = this._TokenExpires || localStorage.getItem("xxTOKEN_EXPIRESxx");
    
    if( !this._Token )
      return false;  

    if( this._TokenExpires - Date.now() < 0 )
      return false;
    
    return true;
  }
  




  /*
  *
  */
  public logout() : any{
    localStorage.removeItem("xxTOKENxx" )
    localStorage.removeItem("xxTOKEN_EXPIRESxx" )
    this._Router.navigate([""]);
  }
  




  /*
  *
  */
  public setInfo( result : any ) : any{


    this._Token        = `${result.token_type} ${result.access_token}`;
    this._TokenExpires = new Date( result[".expires"] );

    localStorage.setItem("xxTOKENxx",         this._Token                        )
    localStorage.setItem("xxTOKEN_EXPIRESxx", result[".expires"]                 )
    localStorage.setItem("xxUSERxx",          `${result.name} ${result.surname}` )
  }
  




  /*
  *
  */
  public isAdmin(){
    let diritti : any[] = JSON.parse( localStorage.getItem("Diritto") ) 
    return diritti.findIndex( ( d : DirittoModel ) => d.ID === 3 ) > -1
  }
}

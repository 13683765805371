import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';
import {DirittoModel} from '../class/models/DirittoModel';

@Injectable({
  providedIn: 'root'
})
export class PageAccessGuard implements CanActivate, CanActivateChild {
  
  constructor( private _authService: AuthService, private _router: Router ){

  }
  
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    let dirittoNecessario : number = null //Amministratore

    if( next.routeConfig["path"] === 'admin' )
      dirittoNecessario = 3
    else
      return true;

    let diritti : any[] = JSON.parse( localStorage.getItem("Diritto") ) 
    let found = diritti.find( ( d : DirittoModel ) => d.ID === dirittoNecessario )

    return !!found;
  }


  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      let dirittoNecessario : number = null //Amministratore
  
      if( next.routeConfig["path"] === 'admin' )
        dirittoNecessario = 3
      else
        return true;
  
      let diritti : any[] = JSON.parse( localStorage.getItem("Diritto") ) 
      let found = diritti.find( ( d : DirittoModel ) => d.ID === dirittoNecessario )
  
      return !!found;
  }
  
}

import { Component } from '@angular/core';
import {fromEvent, noop} from 'rxjs';
import {UtenteService} from './services/api-controller/utente';

@Component({
  selector: 'app-root',
  template: `<router-outlet></router-outlet>`,
})
export class AppComponent {

  lastClick: Date;
  lastClickSend: Date;

  constructor(
    private utenteService: UtenteService
  ) {
    fromEvent(window, 'click' )
      .subscribe( _ => this.lastClick = new Date() );

    //setInterval( () => this.sendLaskClick( ), 5000 );
  }


  private sendLaskClick() {
    if ( this.lastClick === this.lastClickSend ) {
      return;
    }
    this.lastClickSend = this.lastClick;
    this.utenteService.sendLastAction$( this.lastClickSend )
      .subscribe( noop );
  }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageAccessGuard } from './shared/guards/page-access.guard';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'planner',
    loadChildren: './pages/planner/planner.module#PlannerModule',
    canActivate:[PageAccessGuard]
  },
  {
    path: 'login',
    loadChildren: './pages/login/login.module#LoginModule'
  },
  {
    path: 'admin',
    loadChildren: './pages/admin/admin.module#AdminModule',
    canActivate:[PageAccessGuard]
  },
  {
    path: 'risorse-utente',
    loadChildren: './pages/utente/utente.module#UtenteModule',
    canActivate:[PageAccessGuard]
  },
  {
    path: 'missioni',
    loadChildren: './pages/missioni/missioni.module#MissioniModule',
    canActivate:[PageAccessGuard]
  },
  {
    path: 'indisponibilita',
    loadChildren: './pages/indisponibilita/indisponibilita.module#IndisponibilitaModule',
    canActivate:[PageAccessGuard]
  },
  {
    path: 'user_settings',
    loadChildren: './pages/user-settings/user-settings.module#UserSettingsModule',
    canActivate:[PageAccessGuard]
  },
  {
    path: 'risorse_associate',
    loadChildren: './pages/risorse-associate/risorse-associate.module#RisorseAssociateModule',
    canActivate:[PageAccessGuard]
  },
  {
    path: 'tasks',
    loadChildren: './pages/tasks/tasks.module#TasksModule',
    //canActivate:[PageAccessGuard]
  },
  {
    path: 'rendicontazione',
    loadChildren: './pages/rendicontazione/rendicontazione.module#RendicontazioneModule',
    //canActivate:[PageAccessGuard]
  },
  {
    path: 'clienti',
    loadChildren: './pages/clienti/clienti.module#ClientiModule',

  },
  {
    path: 'progetti',
    loadChildren: './pages/progetti/progetti.module#ProgettiModule',

  },
  {
    path: 'reports',
    loadChildren: './pages/reports/reports.module#ReportsModule',

  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import HttpRequestType from '../api/enums/EnumHttpRequestType';
import { Subscription } from 'rxjs';
import UtenteModel from 'src/app/shared/class/models/UtenteModel';
import { UtenteQuery } from 'src/app/shared/class/queries/UtenteQuery';




/**
 * Gestisce la comunicazione tra client e server per l'entità utente
 */
@Injectable({
  providedIn: 'root'
})
export class UtenteService {

  constructor(private api: ApiService) { }

  /**
  * GET:
  *
  * Richiede la lista degli utenti al server
  * @param {UtenteQuery} filter   Query per la lista
  * @param {any} fnSuccess   call back success
  * @param {any} fnError   call back error
  * @returns res-> res.Data=lista utenti
  */
  public listaUtenti(filter: UtenteQuery, fnSuccess: any, fnError: any): Subscription {
    return this.api.sendRequest(HttpRequestType.GET, '/api/Utenti/Lista', filter).subscribe(fnSuccess, fnError);
  }


  /**
   * GET:
   *
   *
   * @param filter
   * @param fnSuccess
   * @param fnError
   */
  public paginate(filter: UtenteQuery, fnSuccess: any, fnError: any): Subscription {
    return this.api.sendRequest(HttpRequestType.GET, '/api/Utenti/Paginate', filter).subscribe(fnSuccess, fnError);
  }



  /**
  * GET:
  *
  * Richiede i dati dell utente che ha eseguito l'accesso al server
  * @param {any} fnSuccess   call back success
  * @param {any} fnError   call back error
  * @returns Subscription
  */
  public infoUtente(fnSuccess: any, fnError: any): Subscription {
    return this.api.sendRequest(HttpRequestType.GET, '/api/user/info').subscribe(fnSuccess, fnError);
  }

  /**
  * POST:
  *
  * aggiunge o modifica l'utente
  * @param {UtenteModel} body   dati utente
  * @param {any} fnSuccess   call back success
  * @param {any} fnError   call back error
  * @returns res-> res.Data=utente
  */
  public aggiungiModificaUtente(body: UtenteModel, fnSuccess: any, fnError: any): Subscription {
    return this.api.sendRequest(HttpRequestType.POST, '/api/User/Registra', null, body).subscribe(fnSuccess, fnError);
  }

  /**
  * POST:
  *
  * Cambia password dell'utente connesso l'utente
  * @param {any} body   password utente
  * @param {any} fnSuccess   call back success
  * @param {any} fnError   call back error
  * @returns Subscription
  */
  public cambiaPasswordUtente(body: any, fnSuccess: any, fnError: any): Subscription {
    return this.api.sendRequest(HttpRequestType.POST, '/api/User/CambiaPassword', null, body).subscribe(fnSuccess, fnError);
  }

  /**
   * POST:
   *
   * Cancella utente
   * @param {number} id   password utente
   * @param {any} fnSuccess   call back success
   * @param {any} fnError   call back error
   * @returns Subscription
   */
  public cancellaUtente(id: number, fnSuccess: any, fnError: any): Subscription {
    return this.api.sendRequest(HttpRequestType.POST, '/api/User/CancellaUtente', null, id).subscribe(fnSuccess, fnError);
  }

  /**
  * POST:
  *
  * Reset password dell'utente
  * @param {number} id   id utente
  * @param {any} fnSuccess   call back success
  * @param {any} fnError   call back error
  * @returns res-> res.Data=nuova password generata dal server
  */
  public resetPassword(id: number, fnSuccess: any, fnError: any): Subscription {
    return this.api.sendRequest(HttpRequestType.POST, '/api/User/ResetPasswordByAdmin', null, id).subscribe(fnSuccess, fnError);
  }

  /*
  */
  public salvaImpostazioni( impostazioni : Object, fnSuccess: any, fnError: any): Subscription {
    const model = { Impostazioni : JSON.stringify( impostazioni ) };
    return this.api.sendRequest(HttpRequestType.POST, '/api/User/SalvaImpostazioni', null, model ).subscribe(fnSuccess, fnError);
  }


  public sendLastAction$( date: Date ) {
    return this.api.sendRequest(HttpRequestType.POST, '/api/Utenti/RegistraUltimaAzione', null, { DateTime : date } );
  }


}
